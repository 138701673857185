<template>
  <div class="bbr-video--tutorial-page">
    <main-app-bar>
      <template v-slot:title>
        <span v-if="video.id"> {{ video.title }} </span>
        <span v-else> New Video Content </span>
      </template>

      <template v-slot:top-actions>
        <v-btn color="error" @click="deleteVideoContent" text dark>
          <v-icon class="mr-3"> {{ icons.delete }} </v-icon>
          Delete Video Content
        </v-btn>
      </template>

      <template v-slot:actions>
        <arrow-button
          :done="stage > 0"
          :to="{
            name: 'video.details',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-right
          replace
        >
          Video Details
        </arrow-button>

        <arrow-button
          :done="stage > 1"
          :to="{
            name: 'video.programs',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-right
          arrow-left
          replace
        >
          Video Programs
        </arrow-button>
        <arrow-button
          :done="stage > 2"
          :to="{
            name: 'video.categories',
            params: { id: $attrs.id },
          }"
          :disabled="!next"
          arrow-left
          replace
        >
          Video Categories
        </arrow-button>
      </template>
    </main-app-bar>

    <div class="mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>

    <confirm-dialog ref="confirmDelete" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import ArrowButton from '@/components/elements/ArrowButton'
import MainAppBar from '@/layouts/shared/MainAppBar'
import Video from '@/models/Video'

import { mdiTrashCanOutline } from '@mdi/js'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'VideoPage',

  components: {
    ConfirmDialog,
    ArrowButton,
    MainAppBar,
  },

  data() {
    return {
      video: {},
      icons: {
        delete: mdiTrashCanOutline,
      },
    }
  },

  computed: {
    ...mapGetters({
      selectedVideo: 'videos/selectedVideo',
    }),

    next() {
      return this.$attrs.id || false
    },

    stage() {
      return this.$route.meta ? this.$route.meta.stage : 1
    },
  },

  methods: {
    ...mapMutations({
      removeVideo: 'videos/removeVideo',
    }),

    async deleteVideoContent() {
      const confirm = await this.$refs.confirmDelete.open(
        'Delete video content',
        'Are you sure you want to remove this video content?'
      )

      if (confirm) {
        let id = this.$route.params.id

        await new Video({ id }).delete()
        await this.removeVideo(id)

        this.$router.back()
      }
    },
  },

  watch: {
    selectedVideo(video) {
      this.video = video
    },
  },
}
</script>
