var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bbr-video--tutorial-page"},[_c('main-app-bar',{scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.video.id)?_c('span',[_vm._v(" "+_vm._s(_vm.video.title)+" ")]):_c('span',[_vm._v(" New Video Content ")])]},proxy:true},{key:"top-actions",fn:function(){return [_c('v-btn',{attrs:{"color":"error","text":"","dark":""},on:{"click":_vm.deleteVideoContent}},[_c('v-icon',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(_vm.icons.delete)+" ")]),_vm._v(" Delete Video Content ")],1)]},proxy:true},{key:"actions",fn:function(){return [_c('arrow-button',{attrs:{"done":_vm.stage > 0,"to":{
          name: 'video.details',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-right":"","replace":""}},[_vm._v(" Video Details ")]),_c('arrow-button',{attrs:{"done":_vm.stage > 1,"to":{
          name: 'video.programs',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-right":"","arrow-left":"","replace":""}},[_vm._v(" Video Programs ")]),_c('arrow-button',{attrs:{"done":_vm.stage > 2,"to":{
          name: 'video.categories',
          params: { id: _vm.$attrs.id },
        },"disabled":!_vm.next,"arrow-left":"","replace":""}},[_vm._v(" Video Categories ")])]},proxy:true}])}),_c('div',{staticClass:"mt-5 px-lg-12 px-md-6 px-5 pt-4 pb-8"},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1),_c('confirm-dialog',{ref:"confirmDelete"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }