<template>
  <div class="bbr-video--tutorials">
    <main-app-bar>
      <template v-slot:title> Video Contents </template>
      <template v-slot:top-actions>
        <v-btn
          :to="{ name: 'new.video' }"
          class="bg-primary-gradient primary ml-auto"
          depressed
        >
          <v-icon class="mr-2"> {{ icons.add }} </v-icon>
          Add New Video Content
        </v-btn>
      </template>
    </main-app-bar>

    <div class="mt-5 px-12 mb-10">
      <v-row>
        <v-col cols="12">
          <div class="form-container d-flex align-center">
            <div class="search-form">
              <v-text-field
                label="Search Videos"
                data-testid="videos-search-input"
                style="width:400px"
                v-model="search"
                @input="searchChange"
                hide-details
                clearable
                rounded
                solo
                flat
              />
            </div>
          </div>
        </v-col>

        <v-col>
          <video-list
            :meta="meta"
            :videos="videos"
            :loading="loading"
            listFor="Video Tutorials"
            @show="show"
            @loadMore="loadMoreVideos"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import VideoList from '../components/VideoList'
import MainAppBar from '@/layouts/shared/MainAppBar'

import { mapState, mapActions, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import { mdiPlus } from '@mdi/js'

export default {
  name: 'VideoListPage',

  components: {
    VideoList,
    MainAppBar,
  },

  data() {
    return {
      search: null,
      loading: false,

      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    ...mapState({
      filter: (state) => state.videos.filter,
      videos: (state) => state.videos.videos.list,
      meta: (state) => state.videos.videos.listMeta,
    }),
  },

  methods: {
    ...mapActions({
      getVideos: 'videos/getVideos',
    }),

    ...mapMutations({
      clearList: 'videos/clearList',
    }),

    searchChange(key) {
      this.searchVideos()
    },

    searchVideos: debounce(function() {
      this.clearList()
      this.fetchVideos()
    }, 600),

    loadMoreVideos() {
      if (this.meta.current_page < this.meta.last_page) {
        this.fetchVideos(this.meta.current_page + 1)
      }
    },

    async fetchVideos(page = 1) {
      if (this.loading) return

      this.loading = true

      await this.getVideos({ search: this.search, page })

      setTimeout(() => {
        this.loading = false
      }, 600)
    },

    show(videoId) {
      this.$router.push({
        name: 'video',
        params: { id: videoId },
      })
    },
  },
}
</script>
