var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Video Title")]),_c('th',[_vm._v("Video Description")]),_c('th',[_vm._v("Video Public Id")]),_c('th',[_vm._v("Video Image Public Id")]),_c('th',[_vm._v("Video Tags")])])]),_c('tbody',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],attrs:{"infinite-scroll-disabled":_vm.disableListScroll,"infinite-scroll-immediate-check":"","infinite-scroll-distance":"50"}},[_vm._l((_vm.videos),function(video){return _c('tr',{key:video.id,staticClass:"clickable new-tab-link-row"},[_c('td',{staticClass:"py-4 video--title"},[_vm._v(" "+_vm._s(video.title)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
                name: 'video',
                params: { id: video.id },
              }}})],1),_c('td',{staticClass:"py-4 video--description"},[_c('div',{staticClass:"truncate--3"},[_vm._v(" "+_vm._s(video.description)+" ")])]),_c('td',{staticClass:"py-4 video--url"},[_vm._v(" "+_vm._s(video.video_public_id)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
                name: 'video',
                params: { id: video.id },
              }}})],1),_c('td',{staticClass:"py-4 video--url"},[_vm._v(" "+_vm._s(video.image_public_id)+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
                name: 'video',
                params: { id: video.id },
              }}})],1),_c('td',{staticClass:"py-4"},[_vm._v(" "+_vm._s(_vm._f("readable")(video.tags))+" "),_c('router-link',{staticClass:"row-link",attrs:{"to":{
                name: 'video',
                params: { id: video.id },
              }}})],1)])})],2),(_vm.loading)?_c('table-skeleton-loader',{attrs:{"column":"5","row":""}}):_vm._e()],1),(!_vm.hasVideos && !_vm.loading)?_c('no-list',{attrs:{"details":"No Available Videos"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }