<template>
  <div>
    <v-simple-table>
      <thead>
        <tr>
          <th>Video Title</th>
          <th>Video Description</th>
          <th>Video Public Id</th>
          <th>Video Image Public Id</th>
          <th>Video Tags</th>
        </tr>
      </thead>

      <tbody
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="disableListScroll"
        infinite-scroll-immediate-check
        infinite-scroll-distance="50"
      >
        <template>
          <tr
            v-for="video in videos"
            :key="video.id"
            class="clickable new-tab-link-row"
          >
            <td class="py-4 video--title">
              {{ video.title }}
              <router-link
                :to="{
                  name: 'video',
                  params: { id: video.id },
                }"
                class="row-link"
              />
            </td>
            <td class="py-4 video--description">
              <div class="truncate--3">
                {{ video.description }}
              </div>
            </td>
            <td class="py-4 video--url">
              {{ video.video_public_id }}
              <router-link
                :to="{
                  name: 'video',
                  params: { id: video.id },
                }"
                class="row-link"
              />
            </td>
            <td class="py-4 video--url">
              {{ video.image_public_id }}
              <router-link
                :to="{
                  name: 'video',
                  params: { id: video.id },
                }"
                class="row-link"
              />
            </td>
            <td class="py-4">
              {{ video.tags | readable }}
              <router-link
                :to="{
                  name: 'video',
                  params: { id: video.id },
                }"
                class="row-link"
              />
            </td>
          </tr>
        </template>
      </tbody>
      <table-skeleton-loader v-if="loading" column="5" row />
    </v-simple-table>

    <no-list v-if="!hasVideos && !loading" details="No Available Videos" />
  </div>
</template>

<script>
import TableSkeletonLoader from '@/components/elements/loaders/TableSkeleton'
import NoList from '@/components/elements/NoList'
import infiniteScroll from 'vue-infinite-scroll'

export default {
  name: 'VideoList',

  directives: {
    infiniteScroll,
  },

  components: {
    NoList,
    TableSkeletonLoader,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    videos: {
      type: Array,
      default: () => [],
      required: true,
    },
    meta: {
      type: Object,
      required: true,
    },
    listFor: {
      type: String,
      required: true,
    },
  },

  computed: {
    hasVideos() {
      return !!this.videos.length
    },

    disableListScroll() {
      return this.loading || this.meta.current_page >= this.meta.last_page
    },
  },

  methods: {
    loadMore() {
      this.$emit('loadMore', this.meta.current_page + 1)
    },

    showInformations(videoId) {
      this.$emit('show', videoId)
    },
  },
}
</script>

<style lang="scss" scoped>
.video {
  &--description {
    max-width: 400px !important;
  }

  &--url {
    word-wrap: break-word;
    max-width: 250px !important;
  }
}
</style>
